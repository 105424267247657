<template>
  <link href="https://fonts.cdnfonts.com/css/barlow" rel="stylesheet" />
  <div class="hello">
    <div style="display:flex; flex-flow: column;">
      <div style="margin-top: 5vh; display:flex; justify-content: space-around;">
        <img class="icon main-icon" src="@/assets/img/mp-logo.svg" alt="" />
      </div>
      <h1>Widelabs solução Jurídicas de IA</h1>
    </div>
    <input id="hidden-file" ref="fileInput" @change="handleFileUpload" type="file" v-show="false" />
    <div class="main-chatbox">
      <div style="margin-left: 10px; margin-top:10px; display: block;">
        <div style="display: flex;" align="left">
          <input @click="updateSelect(1)" type="radio" id="option1" name="method" value="1" checked>
          <label @click="updateSelect(1)" for="option1">1. Solução de análise de expedientes e sugestões de
            minutas</label>
          <div v-if="selectedMethod == 1" style="margin-left: 5vw;">
            <select v-model="selectedSubMethod">
              <option value="0">
                Selecione que tipo de análise deve ser feita no documento
              </option>
              <option value="1.1.1.1">
                1.1.1.1 Analisar e resumir ocorrência(s) policial(is)
              </option>
              <option value="1.1.1.2">
                1.1.1.2 Analisar e resumir autos ou termos de apreensão ou arrecadação de drogas
              </option>
              <option value="1.1.1.3">
                1.1.1.3 Analisar e resumir exame(s) pericial(is) definitivo(s) de constatação de natureza de substância
              </option>
              <option value="1.1.1.4">
                1.1.1.4 Analisar e resumir termos de apreensão de dinheiro em espécie
              </option>
              <option value="1.1.1.5">
                1.1.1.5 Analisar e resumir termos ou autos de avaliação de objetos
              </option>
              <option value="1.1.1.6">
                1.1.1.6 Analisar e resumir laudo(s) pericial(is) de exame de lesões corporais ou auto(s) de exame(s) de
                corpo de delito
              </option>
              <option value="1.1.1.7">
                1.1.1.7 Analisar e resumir laudo(s) de exame necroscópico e/ou cadavérico
              </option>
              <option value="1.1.1.8">
                1.1.1.8 Analisar e resumir depoimento(s) testemunhal(is)
              </option>
              <option value="1.1.2.1">
                1.1.2.1 Analisar e resumir a peça acusatória
              </option>
              <option value="1.1.2.4">
                1.1.2.4 Analisar e resumir depoimentos
              </option>
              <option value="1.1.2.8">
                1.1.2.8 Analisar despachos e decisões interlocutórias judiciais
              </option>
              <option value="1.1.3.1">
                1.1.3.1 Analisar e resumir petição inicial de ação cível
              </option>
              <option value="1.1.3.2">
                1.1.3.2 Analisar e resumir contestação de ação cível
              </option>
              <option value="1.1.3.3">
                1.1.3.3 Analisar e resumir depoimentos
              </option>
              <option value="1.1.3.4">
                1.1.3.4 Analisar e resumir alegações finais ou memoriais
              </option>
              <option value="1.1.3.5">
                1.1.3.5 Analisar e resumir sentença
              </option>
              <option value="1.2.2">
                1.2.2 Identificar a fase em que se encontra uma ação penal
              </option>
              <option value="1.3.1">
                 1.3.1 Classificar documentos
              </option>
            </select>
          </div>
        </div>
        <div align="left">
          <input @click="updateSelect(2)" type="radio" id="option2" name="method" value="2">
          <label @click="updateSelect(2)" for="option2">2.Solução de transcrição de áudio</label><br>
        </div>
        <div  style="display: flex;"  align="left">
          <input @click="updateSelect(3)" type="radio" id="option3" name="method" value="3">
          <label @click="updateSelect(3)" for="option3">3. Solução de apoio</label><br>
          <div v-if="selectedMethod == 3" style="margin-left: 5vw;">
            <select v-model="selectedSubMethod">
              <option value="0">
                Selecione que tipo de análise deve ser feita no documento
              </option>
              <option value="3">
                Solução de apoio
              </option>
              <option value="3.4">
                Ferramenta de sugestão de rota
              </option>
            </select>
          </div>
        </div>
        
        <div align="left">
          <input @click="updateSelect(4)" type="radio" id="option4" name="method" value="4">
          <label @click="updateSelect(4)" for="option4">4. Solução de Atendimento ao Público Interno e Externo. </label><br>
        </div>
        <div align="left">
          <input @click="updateSelect(5)" type="radio" id="option5" name="method" value="5">
          <label @click="updateSelect(5)" for="option5">5. Solução de Pesquisa </label><br>
        </div>
      </div>
      <div class="input-chatbox">
        <span align="left" v-text="placeholderText" type="text" style="margin-left:2vw"/>
        <img @click="onEnter" class="icon search-icon" src="@/assets/img/lupa.svg" alt="" />

      </div>
      </div>
    </div>
    <div class="chatbox-wrapper">
      <span v-if="isLoading">Aguarde enquanto sua requisição é processada</span>
      <div id="chatbox" class="main-chatmessage">
        <div v-for="(msg, msgIndex) in msgs" :key="msgIndex">
          <div align="left" class="box user-box">
            <img class="icon user-icon" src="@/assets/img/user-chat-icon.svg" alt="" />
            <div class="user-text">
              <span> {{ msg.user }}</span>
            </div>
          </div>
          <div v-for="(document, index) in msg.bot.documents" :key="index" align="left" v-show="document.abstract!=nineString()" class="box bot-box">
            <div class="user-icon-wrapper">
              <!--img class="icon user-icon" src="@/assets/img/vigna-chat-icon.svg" alt="" /-->
            </div>
            <div >
              <b><span v-html="document.method"> </span></b><br/>
              <span v-html="document.abstract"> </span>
              <div v-show="document.method[0]=='4'" v-for="(message,index) in document.messages" :key="index">
                <span> {{ message.user }}</span><br>
                <span v-html="message.bot"> </span>
              </div>
              <div class="search-input">
                <input
                v-if="document"
                style="margin-left: 2vw; width: 55vw;display:block!;"
                v-show="document.method[0]=='4'"
                placeholder="Digite uma pergunta"
                type="text"
                @keyup.enter="sendQuestion(document.question,msgIndex)"
                v-model="document.question"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--img class="icon inf-esq-icon" src="@/assets/img/inf-esq.svg" alt="" /-->
  </div>
</template>

<script>

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      botAnswer: "Justo",
      /*{user:'',
        bot:{documents:['']}
      }*/
      msgs: [],
      textBox: "",
      response: [],
      isLoading: false,
      selectedMethod: 1,
      selectedSubMethod: "0",
      answered: false,
      file: null,
    };
  },
  computed: {
    placeholderText() {
      switch (this.selectedMethod) {
        case 1:
          return 'Faça upload de um arquivo PDF ou TXT'
        case 2:
          return 'Faça upload de um arquivo MP3, MP4, MOV'
        case 3:
          return 'Faça upload de um arquivo TXT ou DOCX'
        case 4:
          return 'Faça upload de um arquivo PDF ou TXT'
        case 5:
          return 'Faça upload de um arquivo PDF ou TXT'
        default:
          return 'Digite uma pergunta ou comando'
      }

    }
  },
  methods: {
    redirect(link) {
      window.open(link, "_blank");
    },
    updateSelect(numb) {
      this.selectedMethod = numb
    },
    updateSubSelect(numb) {
      this.selectedSubMethod = numb
    },
    convertHttpsLinksToAnchors(text) {
      // Regular expression to match links that start with "https" and end with "htm" or "html"
      const linkRegex = /https:\/\/[\w\-._~:/?#[\]@!$&'()*+,;=]*\.htm[l]?/g;

      // Replace matched links with anchor tags
      return text.replace(linkRegex, function (match) {
        return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
      });
    },
    markdownToHTML(markdown) {
      // Replace Markdown headers (h1 - h6)
      
      markdown = markdown.replace(/(#+)(.*)/g, function (match, hashes, text) {
        var level = hashes.length;
        return "<h" + level + ">" + text.trim() + "</h" + level + ">";
      });

      // Replace Markdown bold (**text** or __text__)
      markdown = markdown.replace(
        /\*\*(.*?)\*\*|__(.*?)__/g,
        "<strong>$1$2</strong>"
      );

      // Replace Markdown italic (*text* or _text_)
      markdown = markdown.replace(/\*(.*?)\*|_(.*?)_/g, "<em>$1$2</em>");

      // Replace Markdown inline code (`code`)
      markdown = markdown.replace(/`(.*?)`/g, "<code>$1</code>");

      // Replace Markdown links ([text](url))
      markdown = markdown.replace(
        /\[([^[]+)\]\(([^)]+)\)/g,
        '<a href="$2">$1</a>'
      );
      

      // Replace Markdown images (![alt text](image url))
      
      markdown = markdown.replace(
        /!\[([^[]*)\]\(([^)]+)\)/g,
        '<img src="$2" alt="$1">'
      );
      markdown = markdown.replace(/ {2}\n/g, "<br />\n");
      this.convertHttpsLinksToAnchors(markdown)
      

      // Replace Markdown line breaks (two spaces at the end of a line)
      
      
      let regex = /\s(\d+)\./g;
      markdown = markdown.replace(regex, (match, p1) => `<br>${p1}.`);
      
      return markdown;
    },
    /*
//https://mp2-api-xdmdrkuuba-vp.a.run.app/mp2_solutions/
      
    */
    sendQuestion(question, file){
      if(this.isLoading) return false
      this.isLoading=true
      const axios = require("axios");
      axios.post('https://mp2-api-xdmdrkuuba-vp.a.run.app/mp2/process_solution_4/', {
        'method':'4.1.2',
        'user_input':question
      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
          // Handle success      
          console.log(response)
          let responseDict = {'user':question,'bot':this.markdownToHTML('<p style="max-width:80vw">'+response.data.responses['4.1.2 Fluxo de conversa do bot'].replace('\n','<br>')+'</p>')}
          this.msgs[file].bot.documents[0].messages=[]
          this.msgs[file].bot.documents[0].messages = [...this.msgs[file].bot.documents[0].messages, responseDict];
          this.msgs[file].bot.documents[0].question=""
          this.isLoading = false;
          
        })
        .catch(error => {
          // Handle error
          console.error('Message error', error);
          alert('Error sending the message')
          this.isLoading = false;
        });


      this.isLoading=false
    },
    onEnter() {
      if(this.isLoading) return false
      if(this.selectedMethod==1 && this.selectedSubMethod=='0'){
        alert('Selecione a classe de análise a ser realizada antes de enviar o documento')
        return false
      }
      if(this.selectedMethod==3 && this.selectedSubMethod=='0'){
        alert('Selecione a classe de análise a ser realizada antes de enviar o documento')
        return false
      }
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        if (this.validateFileExtension(fileExtension, this.selectedMethod)) {
          this.file = selectedFile;
          // Optionally, immediately call a method to upload the file
          // this.uploadFile();
        } else {
          alert('Tipo de arquivo não permitido');
          this.$refs.fileInput.value = ''; // Reset the file input
        }
      }
      this.uploadFile()
    },
    nineString(){
      return '<p style="max-width:80vw">9</p>'
    },
    validateFileExtension(extension, method) {
      // Define allowed extensions for each method
      const allowedExtensions = {
        1: ['txt', 'pdf'],
        2: ['mov', 'mp3', 'mp4', 'avi', 'wmv'],
        3: ['txt', 'docx', 'pdf'],
        4: ['txt', 'pdf'],
        5: ['txt', 'pdf'],
      };

      // Check if the extension is in the allowed list for the selected method
      return allowedExtensions[method].includes(extension);
    },
    
    uploadFile() {
      if (!this.file) return;
      this.isLoading=true
      const axios = require("axios");
      const formData = new FormData();
      formData.append('file', this.file);
      let innerMethod = 0
      innerMethod = this.selectedMethod==1||this.selectedMethod==3? this.selectedSubMethod:this.selectedMethod
      formData.append('method', innerMethod)
      // Use your preferred way to send data, e.g., axios
      axios.post('https://mp2-api-xdmdrkuuba-vp.a.run.app/mp2/mp2_solutions/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          // Handle success
          this.msgs = [
            {
              bot: {
                documents: [],
              },
              user: this.textBox,
            },
            ...this.msgs,
          ];
          this.msgs[0].user=this.file.name
          const result_arr = []
          for(const key in response.data.responses){
            result_arr.push([key, response.data.responses[key]]);
          }
          result_arr.forEach((element) => {
            let document={}
            let mkdText = this.markdownToHTML('<p style="max-width:80vw">'+element[1].replace('\n','<br>')+'</p>');
            document.abstract = mkdText;
            document.method = element[0]
            this.msgs[0].bot.documents.push({...document});
          });
          this.textBox = "";
          this.isLoading = false;
          
        })
        .catch(error => {
          // Handle error
          console.error('File upload error', error);
          alert('File upload error')
          this.isLoading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "barlow";
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

i {
  cursor: pointer;
  color: #4ac0de;
}

h1 {
  margin: 0px;
  z-index: 1;
}

.main-header {
  color: #f5f5f5;
  margin-top: 10vh;
}

.hello {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: "";
  
}

.sup-dir-icon {
  position: absolute;
  width: 50vw;
  right: 10vw;
  margin: 0px !important;
}

.inf-esq-icon {
  position: absolute;
  width: 10vw;
  left: 0px;
  bottom: 0px;
  z-index: 0;
}

.main-chatbox {
  position: absolute;
  top: 28vh;
  left: 5vw;
  width: 90vw;
  background-color: #f5f5f5;
  border-radius: 24px;
  max-height: 30vh
}

.input-chatbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatbox-wrapper {
  position: absolute;
  top: 52vh;
  left: 5vw;
  width: 90vw;
  height: 46vh;
  background-color: #f5f5f5;
  border-radius: 24px;
  z-index: 1;
}

.main-chatmessage {
  width: 88.5vw;
  margin-top: 2vh;
  max-height: 65vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
  border-radius: 24px;
}

.tags {
  padding: 10px;
  margin: 5px 10px;
  border-radius: 24px;
  background-color: #82008f;
  color: white;
}

.box {
  background-color: #f5f5f5;
  margin: 20px;
  padding: 2vw;
  border-radius: 24px;
}

.user-box {
  display: flex;
  align-items: center;
}

.bot-box {
  display: flex;
  align-items: stretch;
}

.icon {
  margin-right: 30px;
}

.user-icon-wrapper {
  display: flex;
  align-items: flex-start;
}

.user-icon {
  width: 3vw;
}

.search-input {
  width: 90vw;
  border: 0px;
  font-weight: bold;
  margin-left: 20px;
  background-color: #f5f5f5;
  display: flex;
}

.main-icon {
  width: 20vw;
  max-height: 15vh;
}

.wide-icon {
  width: 20vw;
  max-height: 6vh;
  margin-top: 25px;
}

.search-icon {
  height: 20px;
  width: 32px;
  margin: 20px;
}

.document-name {
  color: #82008f;
  font-weight: bolder;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: #42b983;
  width: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #42b983;
}

::-webkit-scrollbar-button {
  display: none;
}
</style>
